
<template>
<div class="goods_edit">
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <el-form-item label="样品标题" prop="title">
      <el-input v-model="ruleForm.title" maxlength="30" show-word-limit></el-input>
    </el-form-item>
    <el-row>
      <el-col :span="12">
        <el-row>
          <el-col :span="12">
            <el-form-item label="标题标签" prop="title_tag">
              <el-input v-model="ruleForm.title_tag" maxlength="5" show-word-limit placeholder="例：包邮"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="样品规格"  prop="specification">
              <el-input v-model="ruleForm.specification" maxlength="10" show-word-limit placeholder="例：500g/袋"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="样品标签" prop="tags">
          <el-select v-model="ruleForm.tags" multiple placeholder="选择商品标签" style="width: 100%;">
            <el-option
              v-for="item in tagsOptions"
              :key="item.index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上下架时间">
          <el-col :span="11">
            <el-form-item prop="begin_time">
              <el-date-picker type="datetime" placeholder="选择上架日期" v-model="ruleForm.begin_time" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2" style="text-align:center; color:#DCDFE6">—</el-col>
          <el-col :span="11">
            <el-form-item prop="end_time">
              <el-date-picker type="datetime" placeholder="选择下架日期" v-model="ruleForm.end_time" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="样品特点" prop="desc">
          <el-input v-model="ruleForm.desc" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="样品价格" prop="price">
              <el-input-number v-model="ruleForm.price" :precision="2" :step="0.01" :min="0.01" :max="10000000"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="库存量" prop="stock">
              <el-input-number v-model="ruleForm.stock" :step="1" :min="0" :max="10000000"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="供应商" prop="provider">
          <el-input v-model="ruleForm.provider" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="服务支持" prop="service">
          <el-checkbox-group v-model="ruleForm.service">
            <el-checkbox v-for="service in serviceOptions" :label="service" :key="service" >{{service}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="样品参数">
          <el-row class="parameter_list" v-for="parameter in ruleForm.parameters" :key="parameter.key">
            <el-col :span="10">
              <el-form-item>
                <el-input placeholder="填写参数名称" style="width:100%;" v-model="parameter.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="text-align:center;">-</el-col>
            <el-col :span="10">
              <el-form-item>
                <el-input placeholder="填写参数值" v-model="parameter.text"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" :offset="1">
              <el-button icon="el-icon-delete" @click.prevent="removeParameter(parameter)"></el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="21"><el-button style="width:100%;" icon="el-icon-plus" @click="addParameter"></el-button></el-col>
          </el-row>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="样品主图">
      <el-upload
        action="/admin/goods/image"
        list-type="picture-card"
        accept="image/png, image/jpeg"
        :on-preview="handlePictureCardPreview"
        :on-remove="handlePictureRemove"
        :on-success="handlePictureSuccess"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :limit="5">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog width="20%" :visible.sync="dialogVisible">
        <img style="width:100%; border-radius: 3px;" :src="dialogImageUrl">
      </el-dialog>
    </el-form-item>
    <el-form-item label="样品详情">
      <div id="detail"></div>
    </el-form-item>
    <el-form-item label="发布状态" prop="status">
      <el-radio-group v-model="ruleForm.status">
        <el-radio label="1">立即发布</el-radio>
        <el-radio label="2">存为草稿</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
      <el-button @click="showdata">showdata</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import WangEditor from 'wangeditor'
export default {
  name: 'Goods',
  data () {
    return {
      ruleForm: {
        title: '',
        title_tag: '',
        specification: '',
        tags: [],
        begin_time: '',
        end_time: '',
        desc: '',
        price: 0.01,
        stock: 0,
        provider: '',
        service: [],
        content: '',
        parameters: [],
        status: 1,
        images: [],
        id: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入样品标题', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        specification: [
          { required: true, message: '请填写样品规格', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请填写样品金额', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请填写商品特点', trigger: 'blur' }
        ],
        provider: [
          { required: true, message: '请填写商品供应商信息', trigger: 'blur' }
        ]
      },
      // tagsOptions: [{ value: '1', label: '政府推荐' }, { value: '2', label: '企业承诺' }, { value: '3', label: '扶贫证明' }],
      tagsOptions: ['政府推荐', '企业承诺', '扶贫证明'],
      serviceOptions: ['72小时发货', '七天无理由退换货', '832平台认证供应商', '包邮'],
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      editor: null
    }
  },
  methods: {
    showdata () {
      console.log(this.ruleForm)
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
          this.postGoodsData(this.ruleForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.editor.txt.clear()
      this.ruleForm.parameters = []
      this.ruleForm.images = []
      this.fileList = []
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handlePictureSuccess (response, file, fileList) {
      this.ruleForm.images = []
      var that = this
      fileList.forEach(function (image, index) {
        that.ruleForm.images[index] = image.response
      })
    },
    handlePictureRemove (file, fileList) {
      this.ruleForm.images = []
      const that = this
      fileList.forEach(function (image, index) {
        that.ruleForm.images[index] = image.response
      })
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    addParameter () {
      if (this.ruleForm.parameters.length < 6) {
        this.ruleForm.parameters.push({ name: '', text: '' })
      } else {
        this.$message.warning('最多只能添加6个参数')
      }
    },
    removeParameter (parameter) {
      var index = this.ruleForm.parameters.indexOf(parameter)
      this.ruleForm.parameters.splice(index, 1)
    },
    postGoodsData (data) {
      var that = this
      if (this.ruleForm.id !== '') {
        this.axios.put('/admin/goods', data)
          .then(function (response) {
            console.log(response)
            if (response.data.code === 0) {
              that.$message({
                message: response.data.msg,
                type: 'success'
              })
            } else {
              that.$message.error('操作失败！')
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      } else {
        this.axios.post('/admin/goods', data)
          .then(function (response) {
            if (response.data.code === 0) {
              that.$message({
                message: response.data.msg,
                type: 'success'
              })
              that.resetForm('ruleForm')
            } else {
              that.$message.error('操作失败！')
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
  },
  mounted () {
    const editor = new WangEditor('#detail')
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.ruleForm.content = newHtml
    }
    editor.config.uploadImgServer = '/admin/goods/imageineditor'
    editor.config.zIndex = 100
    editor.config.pasteIgnoreImg = true
    editor.config.placeholder = ''
    editor.config.menus = [
      'bold',
      'head',
      'fontSize',
      'italic',
      'underline',
      'strikeThrough',
      'foreColor',
      'justify',
      'image',
      'table',
      'splitLine',
      'undo',
      'redo'
    ]
    // 创建编辑器
    editor.create()
    this.editor = editor
    if (this.$route.params.id) {
      var that = this
      that.axios.get('/admin/goods/' + that.$route.params.id)
        .then(function (response) {
          if (response.data.code === 0) {
            that.ruleForm = response.data.goods
            that.fileList = response.data.images
          }
          editor.txt.html(that.ruleForm.content)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  beforeDestroy () {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style scoped>

</style>
